.Corrections {

}

.Checkbox {
    clear: both;
    display: block;
}

.Checkbox label {
	width: auto;
	display: inline-block;
	clear: none;
}

.Checkbox input {
	width: auto;;
	position: relative;
	margin-right: 5px;
}


.CorrectionsButtons {
    margin: 10px 0 0 50%;
  }
  
  .CorrectionsButtons button, .FileChooser {
        background: #1abc9c;
        color: #FFF;
        margin: 0 auto;
        display: inline-block;
  
        font-style: normal;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid #16a085;
        border-width: 1px 1px 3px;
  
  }
  
  .CorrectionsButtons button {
      padding: 5px 10px 5px 10px;
      font-size: 16px;
  }
  
  .CorrectionsButtons button:hover:enabled,
  .CorrectionsButtons input[type="button"]:hover:enabled
  {
      background: #68BDEA;
  }
  
  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    border-width: 1px 1px 3px;
  }
  
  .Title {
      text-align: center;
      margin: 10px;
  }