.ErrorMessage {
    color: red;
    font-weight: bold;
}

.Landing {
    width: 80%;
    position: relative;
    top: 4rem;
	max-width: 500px;
	padding: 10px 20px;
	background: #f4f7f8;
	margin: 10px auto;
	padding: 20px;
	background: #f4f7f8;
	border-radius: 8px;
	font-family: Georgia, "Times New Roman", Times, serif;
	text-align: left;
}
.Landing fieldset{
	border: none;
}
.Landing legend {
	font-size: 1.4em;
	margin-bottom: 10px;
}
.Landing label {
	display: block;
	margin-bottom: 8px;
}
.Landing input[type="text"],
.Landing input[type="date"],
.Landing input[type="datetime"],
.Landing input[type="email"],
.Landing input[type="number"],
.Landing input[type="search"],
.Landing input[type="time"],
.Landing input[type="url"],
.Landing textarea,
.Landing select {
	font-family: Georgia, "Times New Roman", Times, serif;
	background: rgba(255,255,255,.1);
	border: none;
	border-radius: 4px;
	font-size: 15px;
	margin: 0;
	outline: 0;
	padding: 10px;
	width: 100%;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	background-color: #e8eeef;
	color:#8a97a0;
	-webkit-box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
	box-shadow: 0 1px 0 rgba(0,0,0,0.03) inset;
	margin-bottom: 30px;
}
.Landing input[type="text"]:focus,
.Landing input[type="date"]:focus,
.Landing input[type="datetime"]:focus,
.Landing input[type="email"]:focus,
.Landing input[type="number"]:focus,
.Landing input[type="search"]:focus,
.Landing input[type="time"]:focus,
.Landing input[type="url"]:focus,
.Landing textarea:focus,
.Landing select:focus{
	background: #d2d9dd;
}
.Landing select {
	-webkit-appearance: menulist-button;
	height:40px;
}

.Landing input {
    height:40px;
}

.Landing option {
    background: #e8eeef;
}

.Landing h3 { 
	display: inline-block;
	padding-right: 20px;
}

.Landing span label {
	width: auto;
	display: inline-block;
	clear: none;
	padding-right: 20px;
}

.Landing span input {
	width: auto;;
	position: relative;
	top: 15px;
	margin-right: 5px;
}


.SubmitButton button{
	position: relative;
	display: block;
	padding: 19px 39px 18px 39px;
	color: #FFF;
	margin: 0 auto;
	background: #1abc9c;
	font-size: 18px;
	text-align: center;
	font-style: normal;
	width: 100%;
	border: 1px solid #16a085;
	border-width: 1px 1px 3px;
	margin-bottom: 10px;
}
.SubmitButton button:hover,
.Landing input[type="button"]:hover
{
	background: #68BDEA;
}
