.Planogram {
  height: calc(100% - 200px); /* 60px compensates for the button bar and info description below the map */
  width: 100%;
  position: relative;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .Planogram {
    height: calc(100% - 100px);
  }
}

.Planogram > div:nth-child(2) > div {
  z-index: 18 !important;
}

.Planogram img {
  box-sizing: border-box;
  padding: 1rem;
}

.Planogram canvas {
  background-color:white;
}