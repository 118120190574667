.Buttons {
    margin: 5px 3%;
    justify-content: flex-end;
    display: flex;
    justify-content: space-between;
  }
  .Sections {
      margin: 5px 3%;
      justify-content: flex-end;
      background-color: #000620;
      border-radius: 70px;
    }
    .NextButton, .PreviousButton {
      /* width: 100px; */ background: #FFFFFF;
        color: #000000;
        /* margin: 0 auto; */
        display: inline-block;
        font-size: 18px;
        font-style: normal;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        /* box-shadow:  #000000; */
        border-radius: 50%;
        border-width: 1px 1px 3px;
        /* padding: 2px; */
        margin-bottom: 10px;
  }
  .NextButton:disabled {
      color: #999999;
      background-color: #FFFFFF;
  }
  .PreviousButton:disabled {
      background-color: #FFFFFF;
      color: #999999;
  }
  .StartPauseButton, .InfoButton, .StopButton, .NotesButton {
      width: 150px;
  }
  .SectionButton {
  }
   .Sections button {
        background: #FFFFFF;
        color:#000000;
        display: inline-block;
        font-size: 18px;
        font-style: normal;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border-radius: 50%;
        border-width: 1px 1px 3px;
        margin: 10px;
  }
  .PhotoNoteContainer {
  }
  .PhotoNoteImg {
      width: 100%;
  }
  @media only screen and (max-width: 600px) {
      .NextButton, .PreviousButton {
          width: 50px;
      }
      .StartPauseButton, .InfoButton, .StopButton, .NotesButton {
          width: 50px;
      }
      .Buttons button, .Sections button {
          /* padding: 10px 10px; */
      }
      .Buttons button span, .Sections button span {
          position: relative;
          top: 4px;
      }
      .Sections {
          /* padding-bottom: 100px; */
        }
  }
  /* .Sections input[type="button"]:hover:enabled */
  /* .Buttons button:hover:enabled,
  .Sections button:hover:enabled,
  .Buttons input[type="button"]:hover:enabled
  {
      background: #68BDEA;
  } */
  .ItemDescription {
      background-color: white;
      width: auto;
      height: 75px;
      margin: 20px 10%;
      color: black;
      padding: 10px 0;
  }
  .ItemDescription #deployment-info-container {
      font-size: calc(10px + 1vmin) !important;
  }
  .DeploymentInfo {
      background-color: white;
      border: 3px solid green;
      width: 75%;
      margin: 10px 12.5%;
      color: black;
      padding: 10px 10px;
      font-weight: 600;
  }
  .DeploymentInfo table {
      width: 60%;
      margin-left: 25%;
  }
  .DeploymentInfo td {
      text-align: left !important;
      padding: 0 10px;
      width: 50%;
  }
  @media only screen and (max-width: 600px) {
      .DeploymentInfo table {
          width: 100%;
          margin-left: 0;
      }
      .DeploymentInfo td {
          padding: 0;
      }
  }
  .UpcList {
      text-align: center;
      background-color: white;
      width: auto;
      border: 3px solid black;
      color: black;
      display: inline-block;
      margin: 0 5px;
      border-spacing: 0;
  }
  .UpcList thead tr th {
      min-width: 150px;
      width: 100%;
      border-bottom: 1px solid black;
      border-collapse: separate;
      border-spacing: 5px 5px;
  }
  .UpcList tbody tr td {
      min-width: 150px;
      width: 100%;
      padding: 0;
  }
  .NotesContainer, UPCListContainer {
      max-width: 100%;
  }
  .Notes {
      border: 3px solid black;
      display:inline-block;
      margin: 0 5px;
      min-width: 300px;
      min-height: 200px;
      padding: 5px;
      text-align: left;
  }
  .Notes thead tr th {
      min-width: 300px;
      width: 100%;
      border-bottom: 1px solid black;
      border-collapse: separate;
      text-align: center;
  }
  .InfoContainer {
      width: 80%;
      margin: 10px 10%;
  }
  @media only screen and (min-width: 0) {
      .InfoContainer {
          display: flex-wrap;
      }
  }
  @media only screen and (min-width: 601px) {
      .InfoContainer {
          display: flex;
      }
  }
  .OldLayout {
      width:100%;
      height:100%;
  }
  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #CCCCCC;
    border-width: 1px 1px 3px;
  }
  
  
  
  
  
  
  
  