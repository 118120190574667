.ImageUploadSpan {
  display: block;
}
.UploadButtons {
  margin: 10px 0 0 50%;
}

.UploadButtons button, .FileChooser {
      background: #1abc9c;
      color: #FFF;
      margin: 0 auto;
      display: inline-block;

      font-style: normal;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-image: none;
      border: 1px solid #16a085;
      border-width: 1px 1px 3px;

}

.UploadButtons button {
    padding: 5px 10px 5px 10px;
    font-size: 16px;
}

.FileChooser {
    font-size: 14px;
    padding: 2.5px 5px 2.5px 5px;
    margin: 5px 10px 5px 0;
}

.UploadButtons button:hover:enabled,
.UploadButtons input[type="button"]:hover:enabled
{
	background: #68BDEA;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  border-width: 1px 1px 3px;
}

.Title {
    text-align: center;
    margin: 10px;
}